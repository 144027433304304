<template>
  <div class="particles">
    <div id="particles-js"></div>
    <div class="text">
        <div class="header-title">
            <div class="animate-title">
                <h1><span class="a">Chaitanya Patil</span></h1>
            </div>
            <div class="animate-subtitle">
                <h3><span class="a">Full Stack Web Developer</span></h3>
            </div>
        </div>  
    </div>
  </div>
</template>

<script>
import 'particles.js'
export default {
  name: "Particles",
  mounted() {
    this.initParticles()
  },
  methods: {
    initParticles() {
      window.particlesJS("particles-js", {
                "particles": {
                    "number": {
                        "value": 80,
                        "density": {
                            "enable": true,
                            "value_area": 700
                        }
                    },
                    "color": {
                        "value": ["#BD10E0","#B8E986","#50E3C2","#FFD300","#E86363"]
                    },
                    "shape": {
                        "type": "circle",
                        "stroke": {
                            "width": 0,
                            "color": "#000000"
                        },
                        "polygon": {
                            "nb_sides": 5
                        },
                    },
                    "opacity": {
                        "value": 0.7,
                        "random": false,
                        "anim": {
                            "enable": false,
                            "speed": 1,
                            "opacity_min": 0.1,
                            "sync": false
                        }
                    },
                    "size": {
                        "value": 3,
                        "random": true,
                        "anim": {
                            "enable": false,
                            "speed": 40,
                            "size_min": 0.1,
                            "sync": false
                        }
                    },
                    "line_linked": {
                        "enable": true,
                        "distance": 150,
                        "color": "#ffffff",
                        "opacity": 0.4,
                        "width": 1
                    },
                    "move": {
                        "enable": true,
                        "speed": 4,
                        "direction": "none",
                        "random": false,
                        "straight": false,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {
                            "enable": false,
                            "rotateX": 600,
                            "rotateY": 1200
                        }
                    }
                },
                "interactivity": {
                    "detect_on": "canvas",
                    "events": {
                        "onhover": {
                                "enable": true,
                                "mode": "grab"
                        },
                        "onclick": {
                            "enable": true,
                            "mode": "push"
                        },
                        "resize": true
                    },
                    "modes": {
                        "grab": {
                            "distance": 140,
                            "line_linked": {
                                "opacity": 1
                            }
                        },
                        "bubble": {
                            "distance": 400,
                            "size": 40,
                            "duration": 2,
                            "opacity": 8,
                            "speed": 3
                        },
                        "repulse": {
                            "distance": 200,
                            "duration": 0.4
                        },
                        "push": {
                            "particles_nb": 4
                        },
                        "remove": {
                            "particles_nb": 2
                        }
                    }
                },
                "retina_detect": true
            })
    }
  }
};

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

$(document).ready(function() {
    $(window).scroll(function(event) { // eslint-disable-line no-unused-vars
        let scroll = $(this).scrollTop();
        let opacity = 1 - (scroll / 500);
        if (opacity >= 0) {
            $('.header-title').css('opacity', opacity);
        }
    });
});     
</script>

<style lang="scss" scoped>
  // ======
  // global styles
  // ====== 

canvas {
  display: block;
}

@font-face {
  font-family: LeagueSpartan;
  src: url('../assets/LeagueSpartan-Bold.otf');
}

#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
}

  // ======
  // header styles
  // ====== 

.text {
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translate(50%,-50%);
	color: #fff;
	max-width: 90%;
	font-family: "Chronicle Display Bold",Georgia,serif;
}

.header-title h1 h3 {
    color: white;
    text-shadow: 2px 2px 4px #d1d1d1;
    font-family: arial, sans-serif;
    white-space: nowrap;
    text-transform: uppercase;
    display: inline-block;
}

h1 h3 {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
}

h1 {
    text-align: center;
    font-family: LeagueSpartan;
    font-weight: 700;
    font-size: 3.5em;
    margin-bottom: 20px;
}

h3 {
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 2em;
    font-weight: 500;
    color: #f2f2f2;
    text-align: center;
    padding: 1.2em 0;
}

// ======
// media queries
// ====== 

@media (max-width: 530px) {
    h1 {
        font-size: 2.5em !important;
        line-height: 35px !important;
    }
}

@media (max-width: 750px) {
    h1 {
        font-size: 3em !important;
        line-height: 40px !important;
    }
    
}

@media (max-width: 1024px){
    h1 {
        font-size: 3.5em !important;
        line-height: 60px !important;
    }
    
}

/* Animations for header and subheader*/

.animate-title {
  animation: pop-in .8s ease-out forwards;
}

.animate-subtitle {
    animation: subtitle-pop-in .8s ease-out forwards;
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: translateY(-10rem) scale(.8);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes subtitle-pop-in {
  0% {
    opacity: 0;
    transform: translateY(10rem) scale(.8);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.a {
  display: inline-block;
}

.a:hover {
  text-shadow: 0 0 5px #00e3f8;
}

</style>