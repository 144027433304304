<template>
  <div class="projects">
        <div class="projects-container">
            <h1 class="projects-heading">Recent Projects</h1>
            <div class="projects-divider"></div>
            <div class="mel-nav">
                <ul class="mel-filter-nav">
                    <li class="mel-filter" data-filter="*">All</li>
                    <li class="mel-filter" data-filter=".networking">Networking</li>
                    <li class="mel-filter" data-filter=".webdev">WebDevelopment</li>
                    <li class="mel-filter" data-filter=".internetofthings">IOT</li>
                </ul>
            </div>
            
            <div class="mel-grid">
                <div class="mel-grid-sizer"></div>

                <div class="mel-grid-item internetofthings">
                <div class="mel-item-wrapper">
                    <div class="tile"> 
                        <img src='../assets/digits.png'/>
                        <div class="text">
                            <h1 class="white">Digit recognizer using RPi with Pi Camera</h1>
                            <p class="animate-text">The data is used from the learning stage to allow the Pi Camera to read and recognize digits.</p>
                            <div class="tags"> 
                              <ul class="tag-items"> 
                                <li class="tag-item">Python</li>  
                                <li class="tag-item">OpenCV</li> 
                                <li class="tag-item">Tensorflow</li> 
                              </ul> 
                            </div>
                        </div>
                    </div>
                </div>
                </div><!-- .grid-item -->

                <div class="mel-grid-item webdev">
                <div class="mel-item-wrapper">
                    <div class="tile"> 
                        <img src='../assets/Projectmgmt.png'/>
                        <div class="text">
                            <h1>Project Management Suite</h1>
                            <p class="animate-text">End-to-end project lifecycle management and tracking solution for healthcare projects. Dashboard provides real-time demographics. Handle clients, resources and projects with email notifications.</p>
                            <div class="tags"> 
                              <ul class="tag-items"> 
                                <li class="tag-item">Vue JS</li>  
                                <li class="tag-item">Nuxt</li> 
                                <li class="tag-item">Axios</li>
                                <li class="tag-item">Python</li> 
                              </ul> 
                            </div>
                        </div>
                    </div>
                </div>
                </div><!-- .grid-item -->

                <div class="mel-grid-item webdev">
                <div class="mel-item-wrapper">
                    <div class="tile"> 
                        <img src='../assets/recruit.png'/>
                        <div class="text">
                            <h1>Recruitment Solutions</h1>
                            <h2 class="animate-text">Platform to help recruiters for Healthcare Tools</h2>
                            <p class="animate-text">Online test platform with timely questions based on skills mentioned after healthcare tools and knowledge.</p>
                            <div class="tags"> 
                              <ul class="tag-items"> 
                                <li class="tag-item">Vue JS</li>  
                                <li class="tag-item">Axios</li>
                                <li class="tag-item">SocketIO</li> 
                                <li class="tag-item">Python</li> 
                              </ul> 
                            </div>
                        </div>
                    </div>
                </div>
                </div><!-- .grid-item -->

                <div class="mel-grid-item webdev">
                <div class="mel-item-wrapper">
                    <div class="tile"> 
                        <img src='../assets/weather.png'/>
                        <div class="text">
                            <h1 class="white">Weather Application</h1>
                            <h2 class="animate-text">Provides weather information of inputted locations </h2>
                            <p class="animate-text"></p>
                            <div class="tags weathertag"> 
                              <ul class="tag-items"> 
                                <li class="tag-item">Vue JS</li>  
                                <li class="tag-item">OpenWeatherMap</li> 
                              </ul> 
                            </div>
                            <a class="demo-btn" href="https://weatherapp-cp.herokuapp.com/" target="_blank"> 
                              <span>View Site</span> 
                            </a>
                        </div>
                    </div>
                </div>
                </div><!-- .grid-item -->

                <div class="mel-grid-item internetofthings networking">
                <div class="mel-item-wrapper">
                    <div class="tile">
                        <img src='../assets/Rhmf.jpg'/>
                        <div class="text">
                            <h1 class="white">Health Monitoring Framework</h1>
                            <h2 class="animate-text">Rural Health Monitoring Framework using Raspberry Pi</h2>
                            <p class="animate-text">To fetch patient data through a portable kit consisting of the medical sensors and pushing to cloud.</p>
                            <div class="tags"> 
                              <ul class="tag-items"> 
                                <li class="tag-item">IOT</li>  
                                <li class="tag-item">Python</li> 
                                <li class="tag-item">RaspberryPi</li>
                                <li class="tag-item">Adhoc</li> 
                              </ul> 
                            </div>
                        </div>
                    </div>
                </div>
                </div><!-- .grid-item -->

                

                <div class="mel-grid-item networking">
                <div class="mel-item-wrapper">
                    <div class="tile"> 
                        <img src='../assets/chatroom.png'/>
                        <div class="text">
                            <h1>Chat Room</h1>
                            <h2 class="animate-text">Chat application using socket programming in Python </h2>
                            <p class="animate-text">Built a chat application for multiple users to set up a Chat Room server and allow multiple clients to connect to it using a client-side script.</p>
                            <div class="tags"> 
                              <ul class="tag-items"> 
                                <li class="tag-item">Python</li> 
                                <li class="tag-item">Socket Programming</li> 
                              </ul> 
                            </div>
                        </div>
                    </div>
                </div>
                </div><!-- .grid-item -->

                <div class="mel-grid-item networking internetofthings">
                <div class="mel-item-wrapper">
                    <div class="tile"> 
                        <img src='../assets/cloudss.png'/>
                        <div class="text">
                            <h1>Own Cloud Storage</h1>
                            <h2 class="animate-text">Simple cloud storage using RPi</h2>
                            <p class="animate-text">Built my own cloud storage using DietPi, a lightweight operating system for the Raspberry Pi with access to data from webpage.</p>
                            <div class="tags"> 
                              <ul class="tag-items"> 
                                <li class="tag-item">IOT</li>  
                                <li class="tag-item">Python</li> 
                                <li class="tag-item">RPi</li> 
                              </ul> 
                            </div>
                        </div>
                    </div>
                </div>
                </div><!-- .grid-item -->

                <div class="mel-grid-item webdev">
                <div class="mel-item-wrapper">
                    <div class="tile">
                        <img src='../assets/search1.png'/>
                        <div class="text">
                            <h1>Search Engine</h1>
                            <h2 class="animate-text">A simple search engine using ContextualWebSearch API</h2>
                            <p class="animate-text">I built a simple search engine using the features of Vue components and implementing 3rd party APIs</p>
                            <div class="tags searchenginetag"> 
                              <ul class="tag-items"> 
                                <li class="tag-item">Vue JS</li>  
                                <li class="tag-item">ContextualWebSearch</li> 
                              </ul> 
                            </div>
                            <a class="demo-btn" href="https://searchapp-cp.herokuapp.com/" target="_blank"> 
                              <span>View Site</span> 
                            </a>
                        </div>
                    </div>
                </div>
                </div><!-- .grid-item -->

                <div class="mel-grid-item webdev">
                <div class="mel-item-wrapper">
                    <div class="tile">
                        <img src='../assets/access1.png'/>
                        <div class="text">
                            <h1>Identity and Access Management</h1>
                            <p class="animate-text">The solutions cover applications with differnt types of authentication and authorization needs. Other services are collaborative control, single-sign-on and email notifications.</p>
                            <div class="tags identitytag"> 
                              <ul class="tag-items"> 
                                <li class="tag-item">Vue JS</li>  
                                <li class="tag-item">Python</li> 
                                <li class="tag-item">JWT</li> 
                              </ul> 
                            </div>
                        </div>
                    </div>
                </div>
                </div><!-- .grid-item -->

                <div class="mel-grid-item networking">
                <div class="mel-item-wrapper">
                    <div class="tile">
                        <img src='../assets/v2v.png'/>
                        <div class="text">
                            <h1 class="white">V2V simulation in OMNET++</h1>
                            <p class="animate-text">A network simulation implementing vehicle to vehicle communication for efficient traffic management and to reduce the occurrences of accidents.</p>
                            <div class="tags"> 
                              <ul class="tag-items"> 
                                <li class="tag-item">OMNET++</li>  
                                <li class="tag-item">SUMO</li> 
                                <li class="tag-item">Veins</li>
                              </ul> 
                            </div>
                        </div>
                    </div>
                </div>
                </div><!-- .grid-item -->

                <div class="mel-grid-item internetofthings">
                <div class="mel-item-wrapper">
                    <div class="tile">
                        <img src='../assets/train.png'/>
                        <div class="text">
                            <h1>Railway Destination Monitoring Message Services</h1>
                            <p class="animate-text">A Low cost and flexible location tracking product using Raspberry Pi. RPi with connected sensors tracks the train's location and sends alert messages to travellers as they are about to reach their destination</p>
                            <div class="tags"> 
                              <ul class="tag-items"> 
                                <li class="tag-item">IOT</li>  
                                <li class="tag-item">Python</li> 
                                <li class="tag-item">RaspberryPi</li> 
                              </ul> 
                            </div>
                        </div>
                    </div>
                </div>
                </div><!-- .grid-item -->
            </div><!-- .grid -->     
        </div>
  </div>
</template>

<script src="https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.js"></script>
<script>
export default {
    name: 'Projects',
    methods: {

    }
}
import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Isotope from 'isotope-layout';

// make Isotope a jQuery plugin
jQueryBridget( 'isotope', Isotope, $ );

$(document).ready(function() {

    var grid = $('.mel-grid').isotope({
        itemSelector: '.mel-grid-item',
        columnWidth: '.mel-grid-sizer',
        percentPosition: true
    });

    // bind filter button click
    $('.mel-filter-nav').on( 'click', '.mel-filter', function(){
        var filterValue = $( this ).attr('data-filter');
        grid.isotope({ filter: filterValue });
    });

    // change is-checked class on buttons
    $('.mel-filter-nav').each( function( i, buttonGroup ) {
        var $buttonGroup = $( buttonGroup );
        $buttonGroup.on( 'click', '.mel-filter', function() {
        $buttonGroup.find('.is-checked').removeClass('is-checked');
        $( this ).addClass('is-checked');
        });
    });
});     
</script>

<style lang="scss" scoped>

@font-face {
  font-family: LeagueSpartan;
  src: url('../assets/LeagueSpartan-Bold.otf');
}

.projects {
    background: linear-gradient(to bottom,rgba(0,0,0,.61),rgba(0,0,0,.61),rgba(0,0,0,.61),rgba(0,0,0,.61),rgba(0,0,0,.61)), url('../assets/laptop1.jpg') no-repeat center;
    background-size:cover;
    width: 100%;
    min-height: 100vh;
    height: auto;
    margin: 0;
}

.projects-container {
    padding: 100px;
}

.projects-heading {
    width: 100%;
    text-align: left;
    float: left;
    margin: 0 0 20px 0;
    font-size: 3em;
    font-family: "Chronicle Display Bold",Georgia,serif;
}

.projects-divider {
    float: left;
    width: 10%;
    height: .3rem;
    background: #00b7c7;
    margin-bottom: 20px;
}

.tile
{
  display:inline-block;
  width:30em;
  height:30em;
  margin:10px;
  background-color:#c2c3c3;
  background-size:cover;
  position:relative;
  cursor:pointer;
  transition: all 0.4s ease-out;
  box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.44);
  overflow:hidden;
  color:rgb(0, 0, 0);
  font-family: LeagueSpartan;
  font-size: .8em;
}

.tile img
{
  position:absolute;
  height:100%;
  width:100%;
  top:0;
  left:0;
  z-index:0;
  transition: all 0.4s ease-out;
}

.tile .text
{
  position:absolute;
  padding:2.5em;
  height:calc(100% - 60px);
}

.tile h1
{
  font-weight:300;
  margin:0;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}

.white {
    color:rgb(255, 255, 255) !important;
    // background: rgb(82, 56, 56);

}

.tile h2
{
  font-weight:50;
  margin:20px 0 0 0;
  font-style:italic;
  transform: translateX(200px);
}

.tile p
{
  font-weight:300;
  margin:20px 0 0 0;
  line-height: 25px;
  transform: translateX(-200px);
  transition-delay: 0.2s;
}

.animate-text
{
  opacity:0;
  transition: all 0.6s ease-in-out;
}

.tile:hover
{
  box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.64);
  transform:scale(1.05);
}

.tile:hover img
{
  opacity: 0.2;
}

.tile:hover .animate-text
{
  transform:translateX(0);
  opacity:1;
}

.tile:hover span
{
  opacity:1;
  transform:translateY(0px);
}

@media screen and (max-width: 1000px) {
    .projects-container {
        padding: 50px;
    }
    .demo-btn {
      left: 35vw;
    }
    
    
}

@media screen and (max-width: 768px) {
    .projects-container {
        padding: 10px;
    }
    .demo-btn {
      left: 35vw !important; 
    }
    .tile {
      width: 25em;
    }
}

@media screen and (max-width: 421px) {
    .projects-container {
        padding: 15px;
    }

    .projects-heading {
      padding: 50px 0 0 0;
      font-size: 2.5em;
    }
    .demo-btn {
      left: 40vw !important; 
    }
    li {
      font-size: 1em !important;
      font-weight: 600 !important;
    }
    .mel-nav li {
      padding-right: 15px !important;
    }
    .mel-item-wrapper {
      margin: 0 !important;
    }
    .tile {
      width: 25em;
    }
    .tags .tag-items {
      margin-left: 1.3em !important;
      margin-top: 1em !important;
      padding-inline-start: 0;
    }
    .tags .tag-items .tag-item {
      margin-bottom: 0 !important;
    }

}

.demo-btn {
    display: block;
    position: absolute; 
    cursor: pointer;
    text-transform: uppercase;
    font-family: Gotham,Helvetica,Arial,sans-serif;
    font-weight: 700;
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: .2em;
    padding: .75em 1.25em .625em;
    color: #00b7c7;
    background: 0 0;
    border: 2px solid #00b7c7;
    border-radius: 3px;
    overflow: hidden;
    transition: all .3s ease;
    z-index: inherit;
    text-decoration: none;
    bottom: -15%;
    left: 50%;
    -ms-transform: translateX(-45%);
    transform: translateX(-45%);
}

.demo-btn:hover {
    color: #f2f2f2;
    background: #00b7c7;
}

.tags {
    display: block;
    width: 90%;
    position: absolute; 
    bottom: -30px; 
    font-family: Gotham,Helvetica,Arial,sans-serif;
}

.searchenginetag {
  position: absolute;
  bottom: -20px; 
  left: 50px;
}

.identitytag {
  position: absolute;
  bottom: -65px; 
  left: 60px;
}

.weathertag {
  position: absolute;
  bottom: -20px; 
  left: 60px;
}

.tags .tag-items {
  margin-left: .5em;
}

.tags .tag-items .tag-item {
    display: inline-block;
    padding: 7px 7px 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    color: rgb(226, 226, 226);
    background: #333;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: .5px;
    text-decoration: none;
}

ul{
  list-style-type: none;
}

li {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-size: 1.2em;
    font-weight: 600;
}

li:hover {
    text-decoration: underline;
}

.is-checked{
  color: #00b7c7;
}

/* Filter Style */
.mel-nav ul{
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 15px;
  padding-top: 30px;
}
.mel-nav li{
  padding-right: 20px;
  cursor: pointer;
}

/* Grid Style */
.mel-grid {
  max-width: 100%;
}

.mel-grid:after {
  content: '';
  display: block;
  clear: both;
}

.mel-grid-sizer,
.mel-grid-item{
  width: 25%;
}

.mel-grid-item{
  float: left;
}

.mel-item-wrapper{
  margin: 15px;
  -webkit-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
}

.mel-item-wrapper:hover{
  -webkit-box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.2);
}

@media all and (min-width: 769px) and (max-width: 980px) {
  .mel-grid-item{
    width: 50%;
  }
}

@media (max-width: 768px) {
  .mel-grid-item{
    width: 100%;
  }
}
</style>

