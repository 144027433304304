<template>
  <div class="contactinfo">
        <div class="contact-container">
            <div class="contact">
                <div class="contact-title">
                    Contact Me
                </div>
                <div class="divider-contact"></div>
                <div class="contact-description">
                    Have a cool project in mind or just want to say hi? Feel free to send me a message!
                </div>
            </div>

            <div class="contact-form">
                <div class="contact-form-container">
                    <form action="https://formspree.io/f/mbjpakjz" method="POST">
                        <div>
                            <input type="text" name="name" class="contact-input" required="">
                            <label>Name</label>
                        </div>
                        <div>
                            <input type="email" name="_replyto" class="contact-input" required="">
                            <label>Email</label>
                        </div>
                        <div>
                            <input type="text" name="message" class="contact-input" required="">
                            <label>Message</label>
                        </div>
                        <button type="submit" class="send-btn" value="send">Send</button>
                    </form>
                </div>
                
            </div>
      </div>    
  </div>
</template>

<script>
export default {
    name: "Contact",
    methods: {

    }
}
</script>

<style lang="scss" scoped>

// ======
// global styles
// ====== 

.contactinfo {
    background: linear-gradient(to bottom,rgba(0,0,0,.61),rgba(0,0,0,.61),rgba(0,0,0,.61),rgba(0,0,0,.61),rgba(0,0,0,.61)), url('../assets/dustin-humes.jpg') no-repeat center;
    background-size:cover;
    width: 100%;
    min-height: 90vh;
    height: auto;
    margin: 0;
}

.contact-container {
    padding: 100px;
}

.contact {
    text-align: center;
    margin-bottom: 3.5em;
}

.contact-title {
    font-size: 3em;
    font-family: "Chronicle Display Bold",Georgia,serif;
    margin-bottom: .5em;
}

.contact-description {
    margin: 0 auto;
    max-width: 440px;
    font-size: 1.2em;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

@media only screen and (max-width: 768px) {
    .contact-container {
        padding: 100px 50px;
    }

    .contact-form {
        width: 90%;
        margin: 0 auto;
    }

    .contact-form-container {
        width: 250px !important;
    }

    .contact-form-container form div label {
        font-size: .5em;
    }

    .contact-form-container input {
        margin-bottom: 30px !important;
    }
    
}

@media only screen and (max-width: 430px) {
    .contact-container {
        padding: 100px 25px;
    }

    .contact-form-container input {
        margin-bottom: 30px !important;
    }

    .contact-form {
        width: 90%;
        margin: 0 auto;
    }

    .contact-form-container {
        width: 200px !important;
    }

    .contact-form-container form div label {
        font-size: .5em;
    }
    
}

.divider-contact {
    width: 20%;
    height: .2rem;
    background: #00b7c7;
    margin: 20px auto;
}

.contact-form {
    width: 60%;
    padding: 0;
    margin:30px auto;
}

input {
    background: transparent;
    border: none;
}

label {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2em;
    font-weight: 700;
    text-transform: uppercase;
}

input, select, textarea{
    color: #fff;
}

.contact-form-container {
    width: 400px;
    margin: 20px auto;
}

.contact-form-container input{
  padding: 10px 0;
  margin-bottom: 40px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  border: none;
  border-bottom: 2px solid #999;
}

.contact-form-container form div {
  position: relative;
}

.contact-form-container form div label {
  position: absolute;
  font-size: 1em;
  top: 10px;
  left: 0;
  color: #fff;
  transition: 0.5s;
  pointer-events: none;
}

.contact-form-container input:focus~label,
.contact-form-container input:valid~label {
  top: -15px;
  left: 0;
  color: #00b7c7;
}

.contact-form-container input:focus,
.contact-form-container input:valid {
  border-bottom: 2px solid #00b7c7;
}

.send-btn {
    display: block;
    position: relative;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    font-family: Gotham,Helvetica,Arial,sans-serif;
    line-height: 1.5;
    padding: 12px 20px 10px;
    margin: 30px auto 0;
    color: #00b7c7;
    background: 0 0;
    border: 2px solid #00b7c7;
    border-radius: 3px;
    overflow: hidden;
    transition: all .3s ease;
}

.send-btn:hover {
    color: #f2f2f2;
    background: #00b7c7;
}
</style>