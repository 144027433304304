<template>
  <div id="app"> 
    <div class="navigation">
        <input type="checkbox" class="navigation__checkbox" v-model="checkboxValue" id="navi-toggle">
        <label for="navi-toggle" class="navigation__button">
            <span class="navigation__icon">&nbsp;</span>
        </label>

        <div class="navigation__background">&nbsp;</div>

        <nav class="navigation__nav">
            <ul class="navigation__list">
                <li class="navigation__item" v-on:click="uncheck"><a class="navigation__link" href="#intro">Profile</a></li>
                <li class="navigation__item" v-on:click="uncheck"><a class="navigation__link" href="#about">About</a></li>
                <li class="navigation__item" v-on:click="uncheck"><a class="navigation__link" href="#skills">Skills</a></li>
                <li class="navigation__item" v-on:click="uncheck"><a class="navigation__link" href="#projects">Projects</a></li>
                <li class="navigation__item" v-on:click="uncheck"><a class="navigation__link" href="#contact">Contact</a></li>
            </ul>
        </nav>
	  </div>
    <section id="intro" class="section">
      <Particles/>
      <a class="a-intro" href="#about"><span class="span-intro"></span>Scroll</a>
    </section>
    <section id="about" class="section">
      <About/>
    </section>
    <section id="skills" class="section">
      <Skills/>
    </section>
    <section id="projects" class="section">
      <Projects/>
    </section>
    <a class="block-btn" href="https://drive.google.com/file/d/18-bOPNuux9lX1H0bFPRyrOkXeXU2KssF/view?usp=sharing" target="_blank"> 
      <span class="text">View my resume</span> 
      <img class="icon" src='../src/assets/download.png' alt="download resume"> 
    </a>
    <section id="contact" class="section">
      <Contact/>
      <a href="#intro" class="top"><span class="topspan"></span></a>
    </section>
  </div>
</template>

<script>
import Particles from './components/Particles'
import About from './components/About'
import Skills from './components/Skills'
import Contact from './components/Contact'
import Projects from './components/Projects'

export default {
  name: 'App',
  components: {
    Particles,
    About,
    Skills,
    Contact,
    Projects
  },
  data() {
    return {
      checkboxValue: false,
    }; 
  },
  methods: {
    uncheck() {
      this.checkboxValue = false;
    }
  }
}
</script>

<style lang="scss">
@import url(//fonts.googleapis.com/css?family=Lato:300:400);
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');


*, :after, :before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

.navigation {
    &__checkbox {
        display: none;
    }
    &__button {
        background-color: #fff;
        height: 3rem;
        width: 3rem;
        position: fixed;
        top: 2rem;
        right: 2rem;
        border-radius: 50%;
        z-index: 2000;
        box-shadow: 0 1rem 3rem rgba(#000, .1);
        text-align: center;
        cursor: pointer;
    }
    &__background {
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        position: fixed;
        top: 2.5rem;
        right: 2.5rem;
        background-image: radial-gradient(#11414e, #000046);
        z-index: 1000;
        transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);
    }
    &__nav {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -10;
        opacity: 0;
        width: 0;
        transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    &__list {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        list-style: none;
        text-align: center;
        letter-spacing: .3rem;
        width: 100%;
    }
    &__item {
        margin: 1rem;
    }
    &__link {
        &:link,
        &:visited {
            display: inline-block;
            font-size: 1.5rem;
            font-weight: 300;
            padding: .6rem 1.2rem;
            color: #fff;
            text-decoration: none;
            text-transform: uppercase;
            background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #fff 50%);
            background-size: 250%;
            transition: all .4s;
        }
        
        &:hover,
        &:active {
            background-position: 100%;
            color: #0f5163;
        }
    }
    //FUNCTIONALITY
    &__checkbox:checked ~ &__background {
        transform: scale(180);
    }
    &__checkbox:checked ~ &__nav {
        opacity: 1;
        width: 100%;
        z-index: 1500;
    }
    //ICON
    &__icon {
        position: relative;
        margin-top: 1.5rem;
        &,
        &::before,
        &::after {
            width: 1.5rem;
            height: 2px;
            background-color: grey;
            display: inline-block;
        }
        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            transition: all .2s;
        }
        &::before { top: -.5rem; }
        &::after { top: .5rem; }
    }
    &__button:hover &__icon::before {
        top: -.6rem;
    }
    &__button:hover &__icon::after {
        top: .6rem;
    }
    &__checkbox:checked + &__button &__icon {
        background-color: transparent;
    }
    &__checkbox:checked + &__button &__icon::before {
        top: 0;
        transform: rotate(135deg);
    }
    &__checkbox:checked + &__button &__icon::after {
        top: 0;
        transform: rotate(-135deg);
    }
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: auto;
  height: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#intro {
  min-height: 100vh;
}

#projects {
  min-height: 100vh;
}

.section {
  display: block;
  width: 100%;
  height: auto;
  min-height: 700px;
  padding: 0;
  overflow: hidden;
  color: #f2f2f2;
  position: relative;
}

.a-intro {
  padding-top: 60px;
}

.a-intro .span-intro {
  position: absolute;
  bottom: 5%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 5px solid #fff;
  border-bottom: 5px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb 2s infinite;
  animation: sdb 2s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes sdb {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}

@keyframes sdb {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
} 

.block-btn {
	display: block;
	width: 100%;
	min-height: 100px;
	color:#f2f2f2;
	position: relative;
	background: #00909c;
	font-family: Gotham,Helvetica,Arial,sans-serif;
	font-size: 1em;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 1px;
	overflow: hidden;
	transition: background-color .5s linear;
}

.block-btn:focus, .block-btn:hover {
	background:  #00b7c7;
	transition: all .3s ease;
}

.block-btn:focus .text, .block-btn:hover .text {
	opacity: 0;
	visibility: hidden;
	transform: translate3D(0, 4em, 0) scale(.25);
}

.block-btn:focus .icon, .block-btn:hover .icon {
	opacity: 1;
	visibility: visible;
	transform: translate3D(0, 0, 0) scale(1);
}

.block-btn .icon, .block-btn .text {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	text-align: center;
}

.block-btn .text {
	margin: 40px auto;
	opacity: 1;
	visibility: visible;
	transition: transform .4s cubic-bezier(.425,  -.195, .005, 1.215), opacity .13333s linear;
	transform: scale(1);
}

.block-btn .icon {
	width: 50px;
	margin: 25px auto;
	opacity: 0;
	visibility: hidden;
	transition: transform .4s cubic-bezier(.425,  -.195, .005, 1.215), opacity .4s linear;
	transform: translate3D(0, -4em, 0) scale(0);
}

img {
  border-style: none;
}

.top {
  padding-top: 60px;
} 

.top .topspan{
  position: absolute;
  bottom: 5%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 5px solid #fff;
  border-bottom: 5px solid #fff;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-animation: totop 2s infinite;
  animation: totop 2s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes totop {
  0% {
    -webkit-transform: rotate(135deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(135deg) translate(-10px, 10px);
  }
  40% {
    -webkit-transform: rotate(135deg) translate(0, 0);
  }
}

@keyframes totop {
  0% {
    transform: rotate(135deg) translate(0, 0);
  }
  20% {
    transform: rotate(135deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(135deg) translate(0, 0);
  }
} 
</style>
