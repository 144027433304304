<template>
    <div class="skillsinfo">
        <div class="skills-container">
            <h1 class="skills-heading">Skills</h1>
            <div class="skills-divider"></div>
            <div class="skills-wrapper">
                <section class="skills-devsection">
                    <h2 class="skills-heading">
                        <span class="dev dev-icon"></span> 
                        <span class="dev-text">Development</span> 
                    </h2>
                    <div class="wall">
                        <div class="v-boxes">
                        <div class="box">
                            <span class="tooltip">HTML</span>
                            <div class="box-face">
                            <div class="box-text">
                                <font-awesome-icon icon="fa-brands fa-html5" />
                            </div>
                            </div>
                            <div class="box-back">
                            <div class="box-text">
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star-half-stroke" />
                            </div>
                            </div>
                        </div>    
                        <div class="box">
                            <span class="tooltip">CSS</span>
                            <div class="box-face">
                            <div class="box-text"><font-awesome-icon icon="fa-brands fa-css3-alt" /></div>
                            </div>
                            <div class="box-back">
                            <div class="box-text">
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-regular fa-star" />
                            </div>
                            </div>
                        </div>
                        <div class="box">
                            <span class="tooltip">JavaScript</span>
                            <div class="box-face">
                            <div class="box-text"><font-awesome-icon icon="fa-brands fa-square-js" /></div>
                            </div>
                            <div class="box-back">
                            <div class="box-text">
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-regular fa-star" />
                            </div>
                            </div>        
                        </div>
                        <div class="box">
                            <span class="tooltip">Vue</span>
                            <div class="box-face">
                            <div class="box-text"><font-awesome-icon icon="fa-brands fa-vuejs" /></div>
                            </div>
                            <div class="box-back">
                            <div class="box-text">
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-regular fa-star" />
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="v-boxes second">
                        <div class="box">
                            <span class="tooltip">GraphQL</span>
                            <div class="box-face">
                            <div class="box-text"><img src="../assets/graphql-icon.png" alt=""></div>
                            </div>
                            <div class="box-back">
                            <div class="box-text">
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-regular fa-star" />
                            </div>
                            </div>
                        </div>
                        <div class="box">
                            <span class="tooltip">Python</span>
                            <div class="box-face">
                            <div class="box-text"><font-awesome-icon icon="fa-brands fa-python" /></div>
                            </div>
                            <div class="box-back">
                            <div class="box-text">
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star-half-stroke" />
                            </div>
                            </div>
                        </div>
                        <div class="box">
                            <span class="tooltip">Node JS</span>
                            <div class="box-face">
                            <div class="box-text"><font-awesome-icon icon="fa-brands fa-node-js" /></div>
                            </div>
                            <div class="box-back">
                            <div class="box-text">
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-regular fa-star" />
                                <font-awesome-icon icon="fa-regular fa-star" />
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="v-boxes third">
                        <div class="box">
                            <span class="tooltip">PHP</span>
                            <div class="box-face">
                            <div class="box-text">
                                <font-awesome-icon icon="fa-brands fa-php" />
                            </div>
                            </div>
                            <div class="box-back">
                            <div class="box-text">
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-regular fa-star" />
                                <font-awesome-icon icon="fa-regular fa-star" />
                            </div>
                            </div>
                        </div>
                        <div class="box">
                            <span class="tooltip">Docker</span>
                            <div class="box-face">
                            <div class="box-text"><font-awesome-icon icon="fa-brands fa-docker" /></div>
                            </div>
                            <div class="box-back">
                            <div class="box-text">
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star" />
                                <font-awesome-icon icon="fa-solid fa-star-half-stroke" />
                                <font-awesome-icon icon="fa-regular fa-star" />
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="v-boxes forth">
                        <div class="box link">
                            <div class="box-face">
                            <div class="box-text"><font-awesome-icon icon="fa-brands fa-github-alt" /></div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
                <div class="tools-wrapper">
                    <h2 class="skills-heading">
                        <span class="tools tools-icon"></span> 
                        <span class="tools-text">Tools</span> 
                    </h2>
                    <ul class="list"> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">Git + Github</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">Chrome DevTools</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">Command Line</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">SASS</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">Postman</li> 
                    </ul>
                </div>
                <div class="knowledge-wrapper">
                    <h2 class="skills-heading"> 
                        <span class="knowledge-icon knowledge"></span> 
                        <span class="knowledge-text">Knowledge</span> 
                    </h2>
                    <ul class="list"> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">ECMAScript6</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">OpenCV</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">Tensorflow</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">Node / Express</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">Flask / Django</li> 
                    </ul>
                </div>
                <div class="iot-wrapper">
                    <h2 class="skills-heading"> 
                        <span class="iot-icon iot"></span> 
                        <span class="iot-text">Internet Of Things</span> 
                    </h2>
                    <ul class="list"> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">Arduino</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">Raspberry Pi</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">Embedded C</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">ODroid</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">ARM7 Achitecture</li>
                    </ul>
                </div>
                <div class="network-wrapper">
                    <h2 class="skills-heading"> 
                        <span class="network-icon network"></span> 
                        <span class="network-text">Networking Tools</span> 
                    </h2>
                    <ul class="list"> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">Wireshark</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">NMAP</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">OMNET++(SUMO)</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">Cisco Packet Tracer</li> 
                        <li class="list-item"><img class="item-svg" src="../assets/checkmark.svg" alt="checkmark">GNS3</li> 
                    </ul>
                </div>
            </div>
            
        </div>
    </div>
  
</template>

<script>
export default {
    name: "Skills",
    methods: {

    }
}
</script>

<style lang="scss">

.skillsinfo {
    background: linear-gradient(to left bottom,rgba(0, 0, 70, 0.808),rgba(13, 80, 98, 0.808)), url('../assets/laptop2.jpg') no-repeat center;
    background-size: cover;
    width: 100%;
    height: auto;
    margin: 0;
}

.skills-container {
    padding: 100px;
}

.skills-wrapper {
    display: grid;  
    grid-template-columns: 1fr 1fr 1fr;
}

.skills-heading {
    width: 100%;
    text-align: left;
    margin: 0 0 20px 0;
    font-size: 3em;
    font-family: "Chronicle Display Bold",Georgia,serif;
}

.skills-divider {
    width: 10%;
    height: .2rem;
    background: #00b7c7;
    margin-bottom: 20px;
}

.skills-devsection {
  display: inline-flex;
  flex-direction: column;
  justify-content: left;
  padding-left: 75px;
  margin-right: 20px;
  grid-row-start: 1;
  grid-row-end: 3;
}

.tools-wrapper, .knowledge-wrapper, .iot-wrapper, .network-wrapper {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
}

.tools {
    background-image: url('../assets/repair-tools.png');
    background-size: 90%;
}

.knowledge {
    background-image: url('../assets/light-bulb.png');
    background-size: 90%;
}

.iot {
    background-image: url('../assets/ioticon.png');
    background-size: 90%;
}

.network {
    background-image: url('../assets/networkicon.png');
    background-size: 90%;
}

.dev {
    background-image: url('../assets/devicon.png');
    background-size: 90%;
}

.tools-icon, .knowledge-icon, .iot-icon, .network-icon, .dev-icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    position: relative;
    top: -5px;
    display: inline-block;
    vertical-align: text-bottom;
    background-repeat: no-repeat;
    background-position: center center;
}

.tools-text, .knowledge-text, .iot-text, .network-text, .dev-text {
    font-family: Gotham,Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5rem;
}

@media screen and (max-width: 1024px) {
    .skills-container {
        padding: 100px 50px;
    }

    .skills-wrapper {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
        grid-template-rows: auto !important;
    }

    .skills-devsection {
        padding: 0;
        width: 250px;
    }

    .tools-wrapper, .knowledge-wrapper, .iot-wrapper, .network-wrapper {
        display: inline-block !important;
        width: 100% !important;
        padding: 5px;
    }

    .tools-text, .knowledge-text, .iot-text, .network-text, .dev-text, .list-item {
        font-size: 1rem !important;
    }

}

@media screen and (max-width: 900px) {
    .skills-container {
        padding: 100px 50px;
    }

    .skills-wrapper {
        display: grid !important;
        grid-template-columns: 1fr !important;
        grid-template-rows: auto !important;
    }

    .skills-devsection {
        padding: 0;
        width: 250px;
    }

    .tools-wrapper, .knowledge-wrapper, .iot-wrapper, .network-wrapper {
        display: block !important;
        width: 100% !important;
        padding: 5px;
    }

    .tools-text, .knowledge-text, .iot-text, .network-text, .dev-text, .list-item {
        font-size: 1rem !important;
    }

}

@media screen and (max-width: 768px) {
    .skills-container {
        padding: 100px 25px;
    }

    .tools-text, .knowledge-text, .iot-text, .network-text {
        font-size: 1rem !important;
    }

    .tools-wrapper, .knowledge-wrapper, .iot-wrapper, .network-wrapper {
        display: block !important;
        width: 100% !important;
    }

    .skills-devsection {
        padding: 0;
        width: 250px;
    }
}

@media screen and (max-width: 440px) {
    .skills-container {
        padding: 50px 15px;
    } 

    .tools-wrapper, .knowledge-wrapper, .iot-wrapper, .network-wrapper {
        display: block !important;
        width: 100% !important;
    }
    
    .tools-text, .knowledge-text, .iot-text, .network-text, .dev-text {
        font-size: 1rem !important;
    }

    .box-face, .box-back {
        margin-top: 10px !important;
    }
}

.list {
    list-style: none;
    padding: 0;
    margin-top: 30px;
    margin-left: 20px;
}

.list-item {
    margin: 10px 0;
    font-size: 1.4em;
}

.item-svg {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    color: #fff;
    fill: #fff;
}

img {
    border-style: none;
}

.wall {
  display: flex;
}

.v-boxes {
  display: flex;
  flex-direction: column;
  &:not(:first-child){
    margin-left: -75px;
  }
}

.second {
  margin-top: 75px;
}

.third {
  margin-top: 150px;
}

.forth {
  margin-top: 225px;
}

.box {
  width: 100px;
  height: 100px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 25px;
  cursor: pointer;
  &:hover{
   &:not(.link) > .box-face, > .box-back {
     transform: translateZ(100px) rotateX(180deg);
     background-color: transparent !important;
    }
    &:not(.link) > .box-face > .box-text {
      opacity: 0;
    }
    & .tooltip {
      visibility: visible;
      opacity: 1;
    }
    &.link > .box-face {
      background-color: #dedede !important;
      & > .box-text {
        color: #000;
      }
    }
  }
  
  &.link {
    & > .box-face {
      background-color: #323232;
      border: 1px solid #323232;
      box-sizing: border-box;
      transition: background-color .5s;
    }
    & .box-text {
      color: #efefef;
      transition: color .5s;
    }
  }
}

.box-face, .box-back {
  position: absolute;
  top:0;
  z-index:1;
  width: 100px;
  height: 100px;
  background-color: #dedede;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  transform-style: preserver-3d;
  -webkit-transform-style: preserver-3d;
  -moz-transform-style: preserver-3d;
  transition: transform 1s, background-color 0s .3s;
}

.box-face .box-text {
  line-height: 100px;
  text-align: center;
  font-size: 40px;
  color: #666666;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  transform: rotate(-135deg);
  transition: opacity 0s linear .3s;
}

.box-back .box-text {
  line-height: 100px;
  text-align: center;
  font-size: 12px;
  color: #636363;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-135deg) rotateY(180deg);
  transition: opacity 0s linear .3s;
}

.box-back {
  z-index:0;
  background-image: linear-gradient( #fff, #efefef);
}

.box-text img {
  max-width: 32px;
  max-height: 32px;
  -webkit-filter: grayscale(100%) saturate(0);
  filter: grayscale(100%) saturate(0);
  opacity: .5;
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 10px;
  left: 35px;
  width: 80px;
  font-size: 13px;
  color: #000;
  text-align: center;
  border-top: 1px solid rgba(0,0,0,.8);
  padding: 5px 0;
  z-index: 1000;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: visibility 0s linear .3s, opacity 1s linear .3s;
}

.tooltip:after {
  position: absolute;
  top: -5px;
  left: 35px;
  content:' ';
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}
</style>