<template>
  <div class="aboutinfo">
      <div class="about-container">
            <div class="mainheader">
                Hey, I'm Chaitanya
            </div>
            <div class="subheader">
                I'm a detail oriented software engineer passionate about combining beautiful code with beautiful design.
            </div>
            <div class="divider"></div>
            <div class="about-wrapper">
                <img class="photo" src="../assets/myphoto.png">
                <div class="about">
                    <p class="desc">I have experience designing and developing usable and professional websites, from simple landing pages to progressive web applications. I strive to create software that not only functions efficiently using best practice accessibility, but also provides intuitive user experiences.  </p>
                    <p class="desc">I love learning new and better ways to create amazing end-user experiences with efficient, and scalable code. For me work is an ongoing education, and I'm always looking for opportunities to work with those who are willing to share their knowledge. At the end of the day, I'd love to create something beautiful with people that bring out the best in me.</p>
                    <p class="desc">When I'm not in front of a computer screen, I'm probably listening to music, travelling, meditating, or playing table tennis.</p>
                </div> 
            </div>
      </div>    
  </div>
</template>

<script>
export default {
  name: "About",
  methods: {

  }
}  
</script>

<style lang="scss" scoped>

@font-face {
  font-family: Gotham;
  src: url('../assets/GothamMedium.ttf');
}

.desc{
    font-family: Whitney,Helvetica,Arial,sans-serif;
    margin-bottom: 1.5em;
    text-align: left;
    font-size: 1.2em;
    line-height: 1.5em;
}

.aboutinfo {
    background: linear-gradient(to left bottom,rgba(0, 0, 70, 0.808),rgba(0, 0, 0, 0.808)), url('../assets/stars.jpg') no-repeat center;
    background-size:cover;
    width: 100%;
    height: auto;
}

.about-container {
    padding: 100px;
}

.mainheader {
    display: block;
    font-family: "Chronicle Display Bold",Georgia,serif;
    font-weight: 700;
    font-size: 3em;
    margin-bottom: .5em;
}


.subheader {
    width: 60%;
    line-height: 1.5;
    margin-bottom: 1.5em;
    font-size: 1.5em;
    font-weight: 200;
    color: #f2f2f2;
    font-family: Gotham;
} 

.divider {
    width: 10%;
    height: .2rem;
    background: #00b7c7;
    margin-bottom: 1.5em;
}

.about-wrapper {
    display: inline-block;
}

.about {
    width: 60%;
    padding-right: 20px;
    vertical-align: top;
}

@media only screen and (max-width: 900px) {
    .about-container {
        padding: 100px 50px;
    }

    .subheader {
        width: 100%;
    }

    .about-wrapper {
        padding: 0;
    }

    .about-wrapper .photo {
        float: none;
        margin: 10px 0 40px 0;
        width: 300px;
    }

    p {
        font-size: 1.15em;
    }

    .about {
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .about-container {
        padding: 100px 25px;
    }

    .about-wrapper .photo {
        width: 250px;
    }

    .divider {
        width: 20%;
    }
    
}

.photo {
    width: 35%;
    height: auto;
    border-radius: 50%;
    margin-top: -100px;
    margin-left: 70px;
    float: right;
    max-width: 450px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.photo:hover {
    -webkit-filter: grayscale(0);
	filter: grayscale(0);
}
</style>